/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactPlayer from "react-player"

// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";
// plugin that creates slider

// reactstrap components
import {
  Button,
  Modal,
  FormGroup,
  Container,
} from "reactstrap";

function SectionWelcome() {
  const [modal, setModal] = React.useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };

  return (
    <>
      <div
        style={{
          backgroundImage: "url(" + require("assets/img/bg-vision1.jpg") + ")"
        }}
        className="page-header"
        data-parallax={true}
      >
        <div className="filter" />
        <Container>
          <div className="motto text-center">
            <br />
            <h1>เราตั้งใจที่จะนำคนกลับมาหาพระเจ้า และเสริมสร้างเขาให้บรรลุถึงการทรงเรียกในชีวิต</h1>
            <h4>To CONNECT people to God and EMPOWER them to fulfill God’s purpose in life.</h4>
            <br />
            <Button
              onClick={toggleModal}
              className="btn-round mr-1"
              color="neutral"
              target="_self"
              outline
            >
              ดู วิดีโอ เพื่อรู้จักเรา
            </Button>
          </div>
        </Container>
      </div>
      
      <Modal isOpen={modal} toggle={toggleModal}>
          <div className="modal-body text-center">
             
                  <div className='player-wrapper'>
                    <ReactPlayer 
                      controls="true"
                      youtubeConfig={{ playerVars: { showinfo: 1 } }}
                      url="https://youtu.be/spNuJHLmJ_s"
                      className='react-player'
                      width='100%'
                      height='100%'
                    />
                  </div>  

          </div>

                <div className="modal-footer text-center">
                    <Button className="btn-link" color="danger" type="button" onClick={toggleModal}>
                      ปิด
                    </Button>
                </div>
      </Modal>
      
    </>
  );
}

export default SectionWelcome;
