/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactPlayer from "react-player"
// plugin that creates slider

// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

function SectionWhatisChristian() {

  return (
    <>
    <div class="card-blue motto text-center ">Step 1 : เข้าใจความหมายของการเป็นคริสเตียน</div>
    <Container>
    <div className="motto text-center">
         <div>
              <blockquote className="blockquote">
              <br></br>
                  <i>คริสเตียนคือคนที่เชื่อและติดตามในชีวิตและคำสอนของพระเยซูคริสต์</i>
                  <br></br>
                  <br></br>
              </blockquote>
          </div>
    </div>
    <div className="text-left">
         <h4>สาเหตุที่เราติดตามพระเยซูก็เพราะว่า...</h4>
         <h2>เราเป็นคนบาป</h2>
         <h4>เราทุกคนเกิดมาพร้อมกับธรรมชาติบาป เนื่องจากบาปของเราเราจึงไม่สามารถมีความสัมพันธ์กับพระเจ้าได้หากไม่ได้รับการอภัยจากพระองค์</h4><br/>
         <div class="card-grey"><i>เพราะ​ว่า​ทุก​คน​ทำ​บาป และ​เสื่อม​จาก​พระ​สิริ​ของ​พระ​เจ้า</i> - โรม 3:23</div>
         <h2>พระเจ้าช่วย</h2>
         <h4>เพราะเราบาปเราจึงต้องได้รับการอภัยจากพระเจ้า พระเยซูคริสต์จึงสละชีวิตของพระองค์เพื่อเราจะได้รับการอภัย</h4><br/>
         <div class="card-grey m-b-half"><i>แต่​พระ​เจ้า​ทรง​สำ​แดง​ความ​รัก​ของ​พระ​องค์​แก่​เรา คือ​ขณะ​ที่​เรา​ยัง​เป็น​คน​บาป​อยู่​นั้น พระ​คริสต์​สิ้น​พระ​ชนม์​เพื่อ​เรา</i> - โรม 5:8</div>
         <div class="card-grey "><i>เพราะ​ว่า​ค่า​จ้าง​ของ​บาป​คือ​ความ​ตาย แต่​ของ​ประ​ทาน​จาก​พระ​เจ้า​คือ​ชีวิต​นิรันดร์​ใน​พระ​เยซู​คริสต์​องค์​พระ​ผู้​เป็น​เจ้า​ของ​เรา</i> - โรม 6:23</div>
         <h2>เราจึงติดตาม</h2>
         <h4>เรารับการช่วยจากพระเจ้าโดยการมอบชีวิตของเราแก่พระเยซู</h4><br/>
         <div class="card-grey m-b-half"><i>คือ​ว่า​ถ้า​ท่าน​จะ​ยอม​รับ​ด้วย​ปาก​ของ​ท่าน​ว่า​พระ​เยซู​ทรง​เป็น​องค์​พระ​ผู้​เป็น​เจ้า และ​เชื่อ​ใน​ใจ​ว่า พระ​เจ้า​ได้​ทรง​ให้​พระ​องค์​เป็น​ขึ้น​มา​จาก​ความ​ตาย ท่าน​จะ​รอด</i> - โรม 10:9</div>
    </div>
    </Container>
    <div class="card-blue motto text-center">Step 2 : รู้ในสิ่งที่คริสเตียนเชื่อ</div>
    <Container>
    <div className="motto text-center">
         <div>
              <blockquote className="blockquote">
              <br></br>
                  <i>เราเชื่อในพระเจ้าพระบิดา พระบุตรและพระวิญญาณบริสุทธิ์ ทั้งสามรวมเป็นพระเจ้าองค์เดียวเราเรียกว่าตรีเอกานุภาพ ในฐานะผู้ติดตามพระคริสต์ชีวิตเราถูกสร้างใหม่เพื่อดำเนินชีวิตเพื่อพระองค์</i>
                  <br></br>
                  <br></br>
              </blockquote>
          </div>
    </div>
    <div className="text-left">
         <h2>พระเจ้าพระบิดา</h2>
         <h4>พระเจ้าเป็นผู้สร้างทุกสรรพสิ่งและเรียกเราว่าเป็นลูกของพระองค์</h4><br/>
         <div class="card-grey"><i>ลอง​คิด​ดู พระ​บิดา​ได้​ประ​ทาน​ความ​รัก​แก่​เรา​เพียง​ไร​ที่​เรา​ได้​ชื่อ​ว่า​เป็น​ลูก​ของ​พระ​เจ้า และ​เรา​ก็​เป็น​อย่าง​นั้น เหตุ​ที่​ชาว​โลก​ไม่​รู้​จัก​เรา ก็​เพราะ​เขา​ไม่​รู้​จัก​พระ​องค์</i> - 1ยอห์น 3:1</div>
         <h2>พระเยซูพระบุตร</h2>
         <h4>พระเยซูทรงเป็นพระเจ้าโดยสมบูรณ์และเป็นมนุษย์โดยสมบูรณ์ เวลานี้พระองค์ยังทรงพระชนม์อยู่ในสวรรค์</h4><br/>
         <div class="card-grey m-b-half"><i>ผู้​ที่​ยอม​รับ​ว่า​พระ​เยซู​เป็น​พระ​บุตร​ของ​พระ​เจ้า พระ​เจ้า​ทรง​อยู่​ใน​คน​นั้น และ​คน​นั้น​อยู่​ใน​พระ​เจ้า</i> - 1ยอห์น 4:15</div>
         <h2>พระวิญญาณบริสุทธิ์</h2>
         <h4>ฤทธิ์เดชของพระเจ้ามีชีวิตอยู่ในเราผ่านพระวิญญาณบริสุทธิ์</h4><br/>
         <div class="card-grey"><i>เรา​ไม่​ได้​รับ​วิญ​ญาณ​ของ​โลก แต่​ได้​รับ​พระ​วิญ​ญาณ​ซึ่ง​มา​จาก​พระ​เจ้า เพื่อ​จะ​ได้​รู้​ถึง​สิ่ง​ต่างๆ ที่​พระ​เจ้า​ประ​ทาน​แก่​เรา</i> - 1โครินธ์ 2:12</div>
    </div>
    <br></br>
    <div className="motto text-center">
         <Button className="btn-round mr-1" color="info" href="/Beliefs">
                  ศึกษาสิ่งที่เราเชื่อเพิ่มเติม
      </Button>
    </div>
    </Container>
    <div class="card-blue motto text-center ">Step 3 : เติบโตขึ้นในความเชื่อ</div>
    <Container>
    <div className="motto text-center">
         <div>
              <blockquote className="blockquote">
              <br></br>
                  <i>พระเจ้าต้องการมีความสัมพันธ์กับเราและพระองค์ประทานวิธีที่จะทำให้เรารู้จักพระองค์ดีขึ้น เมื่อเรารู้จักพระเจ้าเราเข้าใจแผนการของพระองค์ที่มีต่อเรามากขึ้นและเราพยายามเป็นเหมือนพระเยซูมากขึ้น</i>
                  <br></br>
                  <br></br>
              </blockquote>
          </div>
    </div>
    <div className="text-left">
         <h4>เราเติบโตขึ้นโดยการ...</h4>
         <h2>อ่านพระคริสตธรรมคัมภีร์</h2>
         <h4>พระคัมภีร์เป็นแหล่งความจริงสูงสุดสำหรับความเชื่อของคริสเตียนเกี่ยวกับการดำรงชีวิต พระคำของพระเจ้าเขียนขึ้นเพื่อนำทางเราตลอดชีวิต </h4>
         <h4><a href="https://www.bible.com/app" target="_blank">ดาวน์โหลดแอพพระคัมภีร์</a>และค้นหาแผนการอ่านพระคัมภีร์ที่จะช่วยให้คุณทำให้พระคัมภีร์เป็นส่วนหนึ่งในชีวิตประจำวันของคุณ</h4><br/>
         <div class="card-grey"><i>พระ​คัม​ภีร์​ทุก​ตอน​ได้​รับ​การ​ดลใจ​จาก​พระ​เจ้า และ​เป็น​ประ​โยชน์​ใน​การ​สอน การ​ตัก​เตือน​ว่า​กล่าว การ​แก้ไข​สิ่ง​ผิด และ​การ​อบ​รม​ใน​ความ​ชอบ​ธรรม</i> - 2ทิโมธี 3:16</div>
         <h2>อธิษฐานเป็นชีวิตประจำวัน</h2>
         <h4>การอธิษฐานเป็นการพูดคุยกับพระเจ้าและไม่มีอะไรจะทรงพลังไปกว่าการขอความช่วยเหลือจากพระเจ้า</h4><br/>
         <div class="card-grey"><i>อย่า​กระ​วน​กระ​วาย​ใน​สิ่ง​ใดๆ เลย แต่​จง​ทูล​พระ​เจ้า​ให้​ทรง​ทราบ​ทุก​สิ่ง​ที่​พวก​ท่าน​ขอ โดย​การ​อธิษ​ฐาน​และ​การ​วิง​วอน พร้อม​กับ​การ​ขอบ​พระ​คุณ</i> - ฟีลิปปี 4:6</div>
         <h2>ฟังเสียงพระเจ้า</h2>
         <h4>การพูดคุยกับพระเจ้าไม่ใช่การพูดฝ่ายเดียวแต่พระองค์ต้องการคุยกับเราเช่นกัน ใช้เวลาเรียนรู้พระสุรเสียงของพระองค์และรับฟังการนำทางของพระองค์สำหรับคุณ</h4><br/>
         <div class="card-grey"><i>แกะ​ของ​เรา​ย่อม​ฟัง​เสียง​ของ​เรา เรา​รู้จัก​แกะ​เหล่า​นั้น และ​แกะ​นั้น​ก็​ตาม​เรา</i> - ยอห์น 10:27</div>
         <br></br>
         <div className="motto text-center">
          <Button className="btn-round mr-1" color="info" href="https://youtu.be/WU2Uhyhg1ts" target="_blank">
                  ฟังเสียงพระเจ้าผ่านคำเทศนา
          </Button>
         </div>
         <h2>นมัสการ</h2>
         <h4>เมื่อใดก็ตามที่เราให้ยกย่องพระเจ้าสำหรับความดีของพระองค์เราก็กำลังนมัสการพระองค์ เมื่อเราอธิษฐาน รับใช้หรือช่วยเหลือผู้อื่น เรากำลังทำตามพระประสงค์ของพระเจ้า</h4><br/>
         <div class="card-grey"><i>จง​ถวาย​พระ​เกียรติ​ซึ่ง​ควร​แก่​พระ​นาม​ของ​พระ​องค์​แด่​พระ​ยาห์​เวห์ จง​นมัส​การ​พระ​ยาห์​เวห์​ผู้​ทรง​งด​งาม​ใน​ความ​บริ​สุทธิ์ </i> - สดุดี 29:2</div>
         <div className="motto text-center">
          <br></br>
          <Button className="btn-round mr-1" color="info" href="https://youtu.be/ekb9rooGnT8" target="_blank">
                  ฟังเพลงนมัสการพระเจ้า
          </Button>
         </div>
         <h2>ไปคริสตจักร</h2>
         <h4>ชีวิตเราดีขึ้นเมื่อเราอยู่ด้วยกัน เมื่อเรามีปฏิสัมพันธ์กับคริสเตียนคนอื่น ๆ พวกเขาพร้อมที่สนับสนุนคุณและช่วยให้คุณมีความเชื่อที่เข้มแข็งมากขึ้น</h4><br/>
         <div class="card-grey"><i> เขา​ทั้ง​หลาย​อุทิศ​ตัว​เพื่อ​ฟัง​คำ​สอน​ของ​บรร​ดา​อัคร​ทูต​และ​ร่วม​สา​มัค​คี​ธรรม รวม​ทั้ง​หัก​ขนม​ปัง​และ​อธิษ​ฐาน</i> - กิจการของอัครทูต 2:42</div>
         <div className="motto text-center">
          <br></br>
          <Button className="btn-round mr-1" color="info" href="/locations" target="_blank">
                  ค้นหาคริสตจักร
          </Button>
         </div>
    </div>
    </Container>
    </>
  );
}

export default SectionWhatisChristian;