/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";
// plugin that creates slider
import Slider from "nouislider";
import Pdf from "assets/file/NCF-Constitution-2020.pdf";

// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

function SectionBelief() {

  return (
    <>
      <div className="section section-church text-center">
        <Container>
          <div className="motto text-left">
              <h2>พระเจ้าทรงเป็นพระเจ้าเที่ยงแท้องค์เดียว</h2>
              <h4>ทรงสร้างและกำหนดกฏเกณฑ์ของทุกสรรพสิ่ง ทรงพระชนม์อยู่นิจนิรันดร์ และทรงมีสามพระภาค คือ พระบิดา พระบุตร และพระวิญญาณบริสุทธิ์ ทั้งสามพระภาคเท่าเทียมกันและเป็นพระเจ้าองค์เดียว</h4>
              <ul>
                <li><a href="https://www.bible.com/th/bible/174/GEN.1.1.THSV11" target="_blank">ปมกาล 1:1</a>, <a href="https://www.bible.com/th/bible/174/GEN.1.26.THSV11" target="_blank">26</a>
                , <a href="https://www.bible.com/th/bible/174/GEN.1.27.THSV11" target="_blank">27</a>, <a href="https://www.bible.com/th/bible/174/GEN.3.22.THSV11" target="_blank">3:22</a></li>
                <li><a href="https://www.bible.com/th/bible/174/PSA.90.2.THSV11" target="_blank">สดุดี 90:2</a></li>
                <li><a href="https://www.bible.com/th/bible/174/MAT.28.19.THSV11" target="_blank">มัทธิว 28:19</a></li>
                <li><a href="https://www.bible.com/bible/174/2CO.13.13.THSV11" target="_blank">2โครินธ์ 13:13</a></li>
              </ul>

              <h2>พระเยซูคริสต์ทรงเป็นพระบุตรของพระเจ้า</h2>
              <h4>ทรงเป็นมนุษย์อย่างสมบูรณ์และทรงเป็นพระเจ้าอย่างสมบูรณ์ ทรงเท่าเทียมกับพระบิดาและพระวิญญาณบริสุทธิ์ พระองค์ทรงถือกำเนิดจากหญิงพรหมจารี ทรงปราศจากบาปทั้งสิ้น ทรงสิ้นพระชนม์ที่กางเขนเพื่อไถ่บาปมนุษย์ และทรงฟื้นคืนพระชนม์ในวันที่สาม พระองค์ทรงเสด็จสู่สวรรค์และจะเสด็จกลับมาอีกครั้ง</h4>
              <ul>
                <li><a href="https://www.bible.com/th/bible/174/MAT.1.22.THSV11" target="_blank">มัทธิว 1:22</a>, <a href="https://www.bible.com/th/bible/174/MAT.1.23.THSV11" target="_blank">23</a></li>
                <li><a href="https://www.bible.com/th/bible/174/ISA.9.6.THSV11" target="_blank">อิสยาห์ 9:6</a></li>
                <li><a href="https://www.bible.com/th/bible/174/JHN.1.1-5.THSV11" target="_blank">ยอห์น 1:1-5</a></li>
                <li><a href="https://www.bible.com/bible/174/HEB.4.14-15.THSV11" target="_blank">ฮีบรู 4:14-15</a></li>
                <li><a href="https://www.bible.com/th/bible/174/JHN.1.1-5.THSV11" target="_blank">1โครินธ์ 15:3-4</a></li>
                <li><a href="https://www.bible.com/bible/174/ROM.1.3-4.THSV11" target="_blank">โรม 1:3-4</a></li>
                <li><a href="https://www.bible.com/bible/174/ACT.1.9-11.THSV11" target="_blank">กิจการฯ 1:9-11</a></li>
                <li><a href="https://www.bible.com/bible/174/COS.2.9-10.THSV11" target="_blank">โคโลสี 2:9-10</a></li>
                <li><a href="https://www.bible.com/bible/174/1TIM.6.14-15.THSV11" target="_blank">1ทิโมธี 6:14-15</a></li>
              </ul>

              <h2>พระวิญญาณบริสุทธิ์ทรงประทับในผู้เชื่อทันทีที่บังเกิดใหม่</h2>
              <h4>พระวิญญาณบริสุทธิ์ทรงเป็นพระเจ้าเท่าเทียมกับพระบิดาและพระเยซูคริสต์ เพื่อผูกพันเข้าส่วนในพระเจ้าและเป็นมัดจำแห่งความรอด ทรงเป็นพระผู้ช่วย ผู้ดลใจให้ผู้เชื่อสำนึกบาป นำให้บังเกิดใหม่ฝ่ายวิญญาณ และสถิตอยู่ในผู้เชื่อเพื่อดำเนินชีวิตในฤทธิ์เดชและความบริสุทธิ์  </h4>
              <ul>
                <li><a href="https://www.bible.com/th/bible/174/ACT.1.8.THSV11" target="_blank">กิจการฯ 1:8</a></li>
                <li><a href="https://www.bible.com/th/bible/174/JHN.14.16-17.THSV11" target="_blank">ยอห์น 14:6-7</a>, <a href="https://www.bible.com/th/bible/174/JHN.16.7-13.THSV11" target="_blank">16:7-13</a></li>
                <li><a href="https://www.bible.com/th/bible/174/GAL.5.25.THSV11" target="_blank">กาลาเทีย 5:25</a></li>
                <li><a href="https://www.bible.com/th/bible/174/1CO.2.12.THSV11" target="_blank">1โครินธ์ 2:12</a>, <a href="https://www.bible.com/th/bible/174/1CO.3.16..THSV11" target="_blank">3:16</a></li>
                <li><a href="https://www.bible.com/th/bible/174/EPH.5.25.THSV11" target="_blank">เอเฟซัส 5:23</a></li>
                <li><a href="https://www.bible.com/th/bible/174/2CO.13.14.THSV11" target="_blank">1โครินธ์ 1:14</a></li>
                <li><a href="https://www.bible.com/th/bible/174/1PE.1.2.THSV11" target="_blank">1เปโตร 1:2</a></li>
              </ul>

              <h2>พระคัมภีร์คือถ้อยคำของพระเจ้าที่มาถึงเรา</h2>
              <h4>พระคัมภีร์ถูกเขียนขึ้นโดยมนุษย์ภายใต้การทรงนำของพระวิญญาณบริสุทธิ์ เป็นแหล่งความจริงสูงสุดเกี่ยวกับการดำเนินชีวิตสำหรับคริสเตียน และเพราะพระคัมภีร์ได้รับการดลใจจากพระเจ้า พระคัมภีร์จึงไม่มีข้อผิดพลาด</h4>
              <ul>
                <li><a href="https://www.bible.com/th/bible/174/2TI.3.16.THSV11" target="_blank">2ทิโมธี 3:16</a></li>
                <li><a href="https://www.bible.com/th/bible/174/2PE.1.20-21.THSV11" target="_blank">2เปโตร 1:20-21</a></li>
                <li><a href="https://www.bible.com/th/bible/174/PSA.119.105.THSV11" target="_blank">สดุดี 119:105</a>, <a href="https://www.bible.com/th/bible/174/PSA.119.160.THSV11" target="_blank">160</a>, <a href="https://www.bible.com/th/bible/174/PSA.12.6.THSV11" target="_blank">12:6</a></li>
                <li><a href="https://www.bible.com/th/bible/174/PRO.30.5.THSV11" target="_blank">สุภาษิต 30:5</a></li>
                <li><a href="https://www.bible.com/th/bible/174/ISA.55.11.THSV11" target="_blank">อิสยาห์ 55:11</a></li>
              </ul>

              <h2>มนุษย์คือผู้ที่ถูกสร้างตามพระฉายของพระเจ้า</h2>
              <h4>พระเจ้าทรงรักมนุษย์ ทรงสร้างมนุษย์คู่แรกขึ้นในพระฉายของพระองค์ให้ปราศจากบาป มีสติปัญญา ความสามารถ และสิทธิอำนาจในการเป็นตัวแทนของพระเจ้าเพื่อปกครองโลก แต่มนุษย์คู่แรกทำบาปโดยความตั้งใจจึงทำให้มนุษย์ทั้งปวงตกอยู่ใต้อำนาจความบาป และเสื่อมลงในทุกด้าน  </h4>
              <ul>
                <li><a href="https://www.bible.com/th/bible/174/GEN.1.27.THSV11" target="_blank">ปฐมกาล 1:27</a></li>
                <li><a href="https://www.bible.com/th/bible/174/ISA.53.6.THSV11" target="_blank">อิสยาห์ 53:6</a></li>
                <li><a href="https://www.bible.com/th/bible/174/ROM.3.23.THSV11" target="_blank">โรม 3:23</a></li>
                <li><a href="https://www.bible.com/th/bible/174/ISA.59.1-2.THSV11" target="_blank">อิสยาห์ 59:1-2</a></li>
                <li><a href="https://www.bible.com/th/bible/174/PSA.139.13-16.THSV11" target="_blank">สดุดี 139:13-16</a></li>
                <li><a href="https://www.bible.com/th/bible/174/COL.2.13-15.THSV11" target="_blank">โคโลสี 2:13-15</a></li>
              </ul>

              <h2>ความรอดคือของประทานจากพระเจ้าแต่รับด้วยการตัดสินใจของเรา</h2>
              <h4>ลำพังการทำดีของมนุษย์ไม่สามารถช่วยให้รอดจากบาปได้ พระเจ้ารักและเห็นคุณค่ามนุษย์ทุกคน จึงประทานทางรอดจากบาปและนรกบึงไฟด้วยการพึ่งพาพระคุณความดีของพระเจ้า ผ่านทางความเชื่อในการไถ่บาปของพระเยซูคริสต์ </h4>
              <ul>
                <li><a href="https://www.bible.com/th/bible/174/ROM.6.23.THSV11" target="_blank">โรม 6:23</a></li>
                <li><a href="https://www.bible.com/th/bible/174/EPH.2.8-9.THSV11" target="_blank">เอเฟซัส 2:8-9</a></li>
                <li><a href="https://www.bible.com/th/bible/174/JHN.14.6.THSV11" target="_blank">ยอห์น 14:6</a></li>
                <li><a href="https://www.bible.com/th/bible/174/ROM.5.1.THSV11" target="_blank">โรม 5:1</a>, <a href="https://www.bible.com/th/bible/174/ROM.5.8.THSV11" target="_blank">5:8</a>, <a href="https://www.bible.com/th/bible/174/ROM.10.9-10.THSV11" target="_blank">10:9-10</a></li>
              </ul>

              <h2>ในบั้นปลายมนุษย์จะดำรงอยู่นิจนิรันดร์ทั้งผู้เชื่อและผู้ไม่เชื่อ</h2>
              <h4>เมื่อพระเยซูคริสต์เสด็จกลับมาอีกครั้งหนึ่ง หลังจากนั้นจะมีการพิพากษาใหญ่ โดยคนที่ไม่เชื่อและไม่รับการอภัยจากพระเจ้าต้องรับการพิพากษาเพราะบาปที่ตนได้ทำลงไปและรับโทษในนรกบึงไฟนิรันดร์ ส่วนผู้เชื่อทุกคนจะได้อยู่กับพระเจ้าในอนาคตนิรันดร์ ได้นมัสการพระเจ้าในสวรรค์ซึ่งเต็มไปด้วยพระพรนิรันดร์  </h4>
              <ul>  
                <li><a href="https://www.bible.com/th/bible/174/JHN.3.16.THSV11" target="_blank">ยอห์น 3:16</a>, <a href="https://www.bible.com/th/bible/174/JHN.14.17.THSV11" target="_blank">14:17</a></li>
                <li><a href="https://www.bible.com/th/bible/174/MAT.1.22.THSV11" target="_blank">มัทธิว 1:22</a>, <a href="https://www.bible.com/th/bible/174/MAT.1.23.THSV11" target="_blank">23</a></li>
                <li><a href="https://www.bible.com/th/bible/174/ROM.6.23.THSV11" target="_blank">โรม 6:23</a></li>
                <li><a href="https://www.bible.com/th/bible/174/REV.20.15.THSV11" target="_blank">วิวรณ์ 20:15</a></li>
                <li><a href="https://www.bible.com/th/bible/174/PHI.2.5-11.THSV11" target="_blank">ฟิลิปปี 2:5-11</a></li>
                <li><a href="https://www.bible.com/th/bible/174/MAT.25.31-34.THSV11" target="_blank">มัทธิว 25:31-34</a>, <a href="https://www.bible.com/th/bible/174/MAT.25.41.THSV11" target="_blank">41</a></li>
              </ul>

              <h2>พิธีบัพติศมาคือสัญลักษณ์ของการตาย การถูกฝัง และการฟื้นคืนพระชนม์ของพระเยซูคริสต์</h2>
              <h4>พิธีบัพติศมาเป็นการจุ่มกายมิดน้ำเพื่อแสดงสัญลักษณ์ของการตาย การถูกฝัง และการฟื้นคืนพระชนม์ของพระเยซูคริสต์ ผู้ที่จะรับพิธีนี้ได้ต้องเป็นคนที่กลับใจบังเกิดใหม่แล้วเท่านั้น  </h4>
              <ul>
                <li><a href="https://www.bible.com/th/bible/174/MAT.6.16-17.THSV11" target="_blank">มัทธิว 25:31-34</a>, <a href="https://www.bible.com/th/bible/174/MAT.28.18-20.THSV11" target="_blank">28:18-20</a></li>
                <li><a href="https://www.bible.com/th/bible/174/ACT.2.41.THSV11" target="_blank">กิจการฯ 2:41</a>, <a href="https://www.bible.com/th/bible/174/ACT.8.12.THSV11" target="_blank">8:12</a></li>
                <li><a href="https://www.bible.com/th/bible/174/ROM.6.4.THSV11" target="_blank">โรม 6:4</a></li>
                <li><a href="https://www.bible.com/th/bible/174/COL.2.12.THSV11" target="_blank">โคโลสี 2:12</a></li>
                
              </ul>

              <h2 className="quote">นอกเหนือจากหลักข้อเชื่อเหล่านี้ เราเปิดรับหลักข้อเชื่ออื่นที่มีข้อที่ปฏิบัติแตกต่าง คุณสามารถดูรายละเอียดในส่วนนี้เพิ่มเติมได้ตาม   <a href={Pdf} target="_blank">ธรรมนูญของเรา</a></h2>

              
              <ul>
               </ul>
          </div>
          <div className="motto text-center"><h2>องค์กรคริสตจักรคณะคริสเตียนสานสัมพันธ์จดทะเบียนตามกฎหมายภายใต้สหกิจคริสเตียนแห่งประเทศไทย</h2>
              <h4>  </h4></div>         
        </Container>
        <Button className="btn-round mr-1" color="info" href="/welcome">
                  เราช่วยอะไรคุณได้บ้าง
              </Button>
      </div>
    </>
  );
}

export default SectionBelief;
