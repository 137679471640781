/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Button, Container, Row } from "reactstrap";

// core components

function KnowGodHeader() {

  return (
    <>
     <div
        style={{
          backgroundImage: "url(" + require("assets/img/bg-nextstep.jpg") + ")"
        }}
        className="page-header"
      >
       <div className="filter" />
        <Container>
          <div className="motto text-center">
            <h1>เชื่อพระเยซูแล้วยังไงต่อ?</h1>
            <h4>คุณคงสงสัยว่าต้องทำอะไรต่อเมื่อคุณตัดสินใจต้อนรับพระเยซูเข้ามาในชีวิต
            <br/>
            ลองดู Next Steps ของเราที่จะช่วยให้คุณเดินตามพระเยซูคริสต์</h4><br/>
          </div>
        </Container>
      </div>
    </>
  );
}

export default KnowGodHeader;
