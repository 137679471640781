/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import SectionWelcome from "views/sections/SectionWelcome.js";
import SectionExpectation from "views/sections/SectionExpectation.js";
import MetaTags from 'react-meta-tags';
import Footer from "components/Footers/PageFooter.js";


function WelcomePage() {

  return (
    <>
      <MetaTags>
        <title>NEXUS Fellowship | เราช่วยอะไรคุณได้บ้าง</title>
        <meta property="og:title" content="NEXUS Fellowship | เราช่วยอะไรคุณได้บ้าง" />
        <meta property="og:image" content="assets/img/bgc-welcome.jpg" />
        <meta property="og:description" content="รู้จักพระเจ้า ค้นหาคริสตจักร ใกล้ชิดกับเราได้ที่..." />
      </MetaTags>
      <IndexNavbar />
      <SectionWelcome />
      <SectionExpectation />
      <Footer/>
    </>
  );
}

export default WelcomePage;
