/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates strings
import classnames from "classnames";
import MetaTags from 'react-meta-tags';
import Pdf from "assets/file/NCF-Constitution-2020.pdf";

// reactstrap components
import {
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
   UncontrolledCollapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";



function IndexNavbar() {
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

 
  return (
    
    <Navbar className={classnames("fixed-top")} expand="lg">
      <Container>
        <div className="navbar-translate">
          <NavbarBrand
            data-placement="bottom"
            href="/index"
            target="_self"
            title="องค์กรคริสตจักรคณะคริสเตียนสานสัมพันธ์"
          >
            <img
              alt="..."
              className="nav-logo"
              src={require("assets/img/logo.png")}
            />
          </NavbarBrand>
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="/locations"
                target="_self"
                title=""
              >
                <menu-text>หาโบสถ์</menu-text>
              </NavLink>
            </NavItem>
           <NavItem>
              <NavLink
                data-placement="bottom"
                href="/KnowGod"
                target="_self"
                title=""
              >
                <menu-text>รู้จักพระเจ้า</menu-text>
              </NavLink>
            </NavItem>
            <UncontrolledDropdown nav inNavbar>
                      <DropdownToggle
                        aria-expanded={false}
                        aria-haspopup={true}
                        caret
                        color="default"
                        data-toggle="dropdown"
                       href="/AboutNexus"
                        id="dropdownMenuButton"
                        nav
                        title="เกี่ยวกับเรา"
                        role="button"
                      >
                        <menu-text>รู้จัก NEXUS</menu-text>
                      </DropdownToggle>
                      <DropdownMenu
                        aria-labelledby="dropdownMenuButton"
                        className="dropdown-info"
                      >
                        <DropdownItem
                          href="/AboutNexus"
                          target="_self"
                        >
                          <menu-dropdown-text>เกี่ยวกับเรา</menu-dropdown-text>
                        </DropdownItem>
                        <DropdownItem
                          href="/Beliefs"
                          target="_self"
                        >
                          <menu-dropdown-text>หลักข้อเชื่อ</menu-dropdown-text>
                        </DropdownItem>
                        <DropdownItem
                          href="/Teams"
                          target="_self"
                        >
                          <menu-dropdown-text>ทีมผู้นำ</menu-dropdown-text>
                        </DropdownItem>
                         <DropdownItem
                            href={Pdf}
                            target="_blank"
                        >
                          <menu-dropdown-text>ธรรมนูญ</menu-dropdown-text>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <NavItem>
              <NavLink
                data-placement="bottom"
                href="/NextStep"
                target="_self"
                title=""
              >
                <menu-text>Next Step</menu-text>
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default IndexNavbar;
