/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";

import SectionWhatisChristian from "views/sections/SectionWhatisChristian.js";
import Header from "components/Headers/WhatisChristianHeader.js";

import Footer from "components/Footers/PageFooter.js";
import MetaTags from 'react-meta-tags';


function NextStep() {

  return (
    <>
      <MetaTags>
        <title>NEXUS Fellowship | CONNECT & EMPOWER</title>
        <meta property="og:title" content="NEXUS Fellowship | CONNECT & EMPOWER" />
        <meta property="og:image" content="assets/img/bg.jpg" />
        <meta property="og:description" content="รู้จักพระเจ้า ค้นหาคริสตจักร ใกล้ชิดกับเราได้ที่..." />
      </MetaTags>
      <IndexNavbar />
      <Header />
      <SectionWhatisChristian />
      <Footer/>
    </>
  );
}

export default NextStep;
